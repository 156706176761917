import { useEffect, useState } from "react";
import { Event } from "../utils/interfaces";

export const useWedding = (weddingId?: string) => {
    const [event, setEvent] = useState<Event | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!weddingId) {
            setLoading(false);
            return;
        }

        const appUrl = process.env.REACT_APP_URL;
        const imageUrl = process.env.REACT_APP_URL_IMAGE;

        const fetchEvent = async () => {
            try {
                const response = await fetch(`${appUrl}/api/events/${weddingId}`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Modify the images array to include the REACT_APP_URL_IMAGE
                data.images = data.images.map((img: string) => `${imageUrl}/${img}`);
                setEvent(data);
            } catch (error: unknown) {
                console.error('Failed to fetch event:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchEvent();
    }, [weddingId]);

    return { event, loading, error };
};
