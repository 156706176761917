import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useProduct } from "../../hooks/useProduct";
import ProductButton from "../../utils/productButton";
import { Image } from "primereact/image";
import {HiOutlineCash, HiOutlineScale} from "react-icons/hi";
import { Tooltip } from "primereact/tooltip";
import allergens from "../../utils/allergens";

// Utility function to get allergen descriptions
const getAllergenDescriptions = (allergenNumbers: number[]): string => {
    return allergenNumbers.map(number => allergens[number]).join(', ');
};

export default function ProductDetail() {
    const { id } = useParams<{ id: string }>();
    const { product, loading, error } = useProduct(id as string);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;
    if (!product) return <div>Product not found</div>; // Handle the case where product is null

    // Allergens number and descriptions
    const allergenNumbers = (product.allergens || []).map(Number);
    const allergenDescriptions = getAllergenDescriptions(allergenNumbers);
    // Join allergen numbers with commas
    const allergenNumbersString = allergenNumbers.join(', ');

    const typeNames = product.type_names.map((type, index) => (
        <span key={index} className={"p-chip"}>
            {type}
            {index < product.type_names.length - 1 ? ', ' : ''}
        </span>
    ));

    const returnPage = product.type_names.some((tag: string) => tag.toLowerCase() === 'dezerty') ? '/dezerty' : '/produkty';


    return (
        <div className="content column item-details product">
            <div className={"p-card title"}>
                <Link to={returnPage}>Zpět</Link>
                <h1>{product.name}</h1>
                <span className={"allergens"}>{allergenNumbersString}</span>

                <Tooltip target=".allergens" position="bottom" content={allergenDescriptions} />
            </div>

            <div className={"row"}>
                <Image src={product.image} alt={product.name} className={"info-img"} preview />

                <div className={"p-card item-info"}>
                    <div>
                        {typeNames}
                    </div>
                    <p>{product.description}</p>

                    <span className={"weight"}> <HiOutlineScale/> {product.weight ?? ''}</span>

                    <div className={"info-footer"}>
                        <h3><HiOutlineCash/> {product.price ?? ''} Kč</h3>
                        <ProductButton product={product}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
