import React from "react";
import { Link, useParams } from "react-router-dom";
import { useWedding } from "../../hooks/useWedding";
import { Event } from "../../utils/interfaces";
import "../../css/itemDetails.css";
import { Image } from "primereact/image";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { HiPhone } from "react-icons/hi2";
import { Tooltip } from "primereact/tooltip";
import { Carousel } from 'primereact/carousel';

interface EventDetailProps {
    event: Event | null;
}

const EventDetail: React.FC<EventDetailProps> = ({ event }) => {
    if (!event) return null;

    const imageTemplate = (item: string) => {
        return (
            <div className="carousel-item-container" style={{ height: '60vh' }}>
                <Image
                    src={item}
                    alt={event.name}
                    className="info-img"
                    preview
                    style={{
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            </div>
        );
    };

    return (
        <div className="row">
            <div className="carousel-container" style={{ height: '60vh', marginBottom: '2rem' }}>
                <Carousel
                    value={event.images}
                    numVisible={1}
                    numScroll={1}
                    className="custom-carousel"
                    circular
                    autoplayInterval={3000}
                    itemTemplate={imageTemplate}
                    style={{ height: '100%' }}
                />
            </div>

            <div className="p-card item-info" style={{ width: '100%' }}>
                <p>{event.description}</p>
                <div className="info-footer">
                    <h3 id="price" className="price">Od: {event.price} Kč</h3>
                    <Tooltip target="#price" content="Cena je pouze orientační a odvíjí se podle domluvy"/>
                    <div className="contacts">
                        <h3>Kontaktujte nás:</h3>
                        <a href="mailto:cafenero2022@gmail.com"><HiOutlineAtSymbol />cafenero2022@gmail.com</a>
                        <a href="tel:+420773171883"> <HiPhone />+420 773 171 883 </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function WeddingDetail() {
    const { id } = useParams<{ id: string }>();
    const { event, loading, error } = useWedding(id as string);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="content column item-details event">
            <div className="p-card title">
                <Link to="/svatby">Zpět</Link>
                <h1>{event?.name}</h1>
            </div>

            <EventDetail event={event} />
        </div>
    );
}
