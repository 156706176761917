import { useEffect, useState } from "react";
import { Product } from "../utils/interfaces";

export const useProduct = (productId?: string) => {
    const [product, setProduct] = useState<Product | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!productId) {
            setLoading(false);
            return;
        }

        const appUrl = process.env.REACT_APP_URL;

        const fetchProduct = async () => {
            try {
                const response = await fetch(`${appUrl}/api/products/${productId}`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                //if its start with http it remains the same if it dont add the storage path
                if (!data.image.startsWith("http")) {
                    data.image = `${appUrl}/storage/${data.image}`;
                }
                setProduct(data);
            } catch (error: unknown) {
                console.error('Failed to fetch product:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId]);

    return { product, loading, error };
};
