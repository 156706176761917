import { useEffect, useState } from "react";
import { Order } from "../utils/interfaces";
import useAuth from "./useAuth";
import useUserData from "./useUserData";

const useOrder = (orderId: string) => {
    const isAuthenticated = useAuth();
    const { userData, loading: userLoading, error: userError } = useUserData(isAuthenticated);
    const [order, setOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL;

        const fetchOrder = async () => {
            try {
                const headers: HeadersInit = {
                    "Content-Type": "application/json",
                };

                // Add the user ID to the headers if the user is not an admin
                if (userData?.account_type !== "admin" && userData?.id) {
                    headers["X-User-ID"] = userData.id;
                }

                const response = await fetch(`${appUrl}/api/orders/${orderId}`, {
                    method: "GET",
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Order = await response.json();
                setOrder(data);
            } catch (error: unknown) {
                console.error('Failed to fetch order:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated && !userLoading && !userError) {
            fetchOrder();
        }
    }, [isAuthenticated, userLoading, userError, userData?.account_type, userData?.id, orderId]);

    return { order, loading, error };
};

export default useOrder;