import React, { useState, useEffect } from "react";
import CartItems from "./cartItems";
import SummaryCart from "./summaryCart";
import useAuth from "../../hooks/useAuth";
import useUserData from "../../hooks/useUserData";
import { PayMethod } from "./payMethod";
import FinishOrder from "./finishOrder"; // Import the new FinishOrder component

// Enum to manage steps
export enum Step {
    Cart,
    Payment,
    Summary,
    Finish
}

// Define the Product type
interface Product {
    id: string;
    name: string;
    price: number;
    quantity: number;
    image_small: string;
}

// CartFlow component to manage the flow between steps
export default function CartFlow() {


    const [currentStep, setCurrentStep] = useState<Step>(Step.Cart);
    const idCookie = useAuth();
    const { userData, loading, error } = useUserData(idCookie);
    const [payMethod, setPayMethod] = useState<string>("cash");
    const [deliveryMethod, setDeliveryMethod] = useState<string>("personal");
    const [cartItems, setCartItems] = useState<Product[]>([]);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        city: "",
        street: "",
        postalCode: ""
    });

    //determinate if the url has query params containing the order_id or error if yes then set the current step to finish
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("order_id") || urlParams.has("error")) {
            setCurrentStep(Step.Finish);
        }
    }, [setCurrentStep]);

    useEffect(() => {
        const storedCart = localStorage.getItem("cart");
        if (storedCart) {
            try {
                const parsedCart = JSON.parse(storedCart);
                if (typeof parsedCart === 'object' && parsedCart !== null) {
                    const itemsArray = Object.values(parsedCart) as Product[];
                    setCartItems(itemsArray);
                } else {
                    console.error('Stored cart is not an object:', parsedCart);
                }
            } catch (error) {
                console.error('Error parsing stored cart:', error);
            }
        }
    }, [setCartItems]);

    useEffect(() => {
        const cartObject = cartItems.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {} as { [key: string]: Product });
        localStorage.setItem("cart", JSON.stringify(cartObject));
    }, [cartItems]);

    useEffect(() => {
        if (idCookie && userData) {
            setFormData({
                firstName: userData.first_name || "",
                lastName: userData.last_name || "",
                email: userData.email || "",
                phoneNumber: userData.phone_number || "",
                city: deliveryMethod === "personal" ? "Moravská Ostrava" : "",
                street: deliveryMethod === "personal" ? "Matiční" : "",
                postalCode: deliveryMethod === "personal" ? "2740-20" : ""
            });
        }
    }, [idCookie, userData, deliveryMethod]);

    if (new Date() < new Date('2024-09-01')) {
        return(
            <div className={"content justify-center"}>
                <h1>
                    Stránka není dostupná před 2024-09-01
                </h1>
            </div>);
    }

    return (
        <div className={"content justify-center"}>
            {currentStep === Step.Cart &&
                <CartItems
                    setCurrentStep={setCurrentStep}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                />
            }

            {currentStep === Step.Payment && (
                <PayMethod
                    setCurrentStep={setCurrentStep}
                    userData={userData}
                    loading={loading}
                    error={error}
                    formData={formData}
                    setFormData={setFormData}
                    payMethod={payMethod}
                    setPayMethod={setPayMethod}
                    deliveryMethod={deliveryMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    idCookie={!!idCookie}
                />
            )}

            {currentStep === Step.Summary && (
                <SummaryCart
                    setCurrentStep={setCurrentStep}
                    formData={formData}
                    payMethod={payMethod}
                    deliveryMethod={deliveryMethod}
                    userData={userData}
                    cartItems={cartItems}
                />
            )}

            {currentStep === Step.Finish && (
                <FinishOrder
                    setCurrentStep={setCurrentStep}
                    payMethod={payMethod}
                />
            )}
        </div>
    );
}
