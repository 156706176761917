// src/hooks/useAuth.js
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';


const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkToken = () => {
            const token = Cookies.get('id');
            setIsAuthenticated(!!token);
        };

        checkToken();

        // Optionally, you can set up an interval to periodically check the token
        const intervalId = setInterval(checkToken, 1000); // Check every 60 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return isAuthenticated;
};

export default useAuth;
