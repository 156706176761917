// Define the allergens object with a type assertion
const allergens = {
    1: "Obiloviny obsahující lepek",
    2: "Korýši",
    3: "Vejce",
    4: "Ryby",
    5: "Arašídy",
    6: "Sója",
    7: "Mléko",
    8: "Skořápkové plody",
    9: "Celer",
    10: "Hořčice",
    11: "Sezamová semena",
    12: "Oxid siřičitý a siřičitany",
    13: "Vlčí bob (lupina)",
    14: "Měkkýši"
} as { [key: number]: string };

export default allergens;