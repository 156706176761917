export enum Translation {
    delivered = "Doručeno",
    cancelled = "Zrušeno",
    paid = "Zaplaceno",
    unpaid = "Nezaplaceno",
    pending = "Čekající"
}

export const translateStatus = (status: keyof typeof Translation): string => {
    return Translation[status] || status;
};

const payTrans = {
    "card": "Kartou",
    "cash": "Hotově"
}

export const translatePayment = (payment: keyof typeof payTrans): string => {
    return payTrans[payment] || payment;
};