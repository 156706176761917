import { useEffect, useState } from "react";
import { Order } from "../utils/interfaces";
import useAuth from "./useAuth";
import useUserData from "./useUserData";

const useOrders = () => {
    const isAuthenticated = useAuth();
    const { userData, loading: userLoading, error: userError } = useUserData(isAuthenticated);
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL;

        const fetchOrders = async () => {
            try {
                const headers: HeadersInit = {
                    "Content-Type": "application/json",
                };

                // Add the user ID to the headers if the user is not an admin
                if (userData?.account_type !== "admin" && userData?.id) {
                    headers["X-User-ID"] = userData.id;
                }

                const response = await fetch(`${appUrl}/api/orders`, {
                    method: "GET",
                    credentials: "include",
                    headers,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Order[] = await response.json();
                setOrders(data);
            } catch (error: unknown) {
                console.error('Failed to fetch orders:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated && !userLoading && !userError) {
            fetchOrders();
        }
    }, [isAuthenticated, userLoading, userError, userData?.account_type, userData?.id]);

    return { orders, loading, error };
};

export default useOrders;