import React, {useState} from "react";
import { Step } from "./cartFlow"; // Ensure this is correctly imported
import { DataView } from "primereact/dataview";
import { HiOutlineAtSymbol, HiOutlineCash, HiOutlineClock, HiOutlineCreditCard, HiOutlineMap } from "react-icons/hi";
import { HiPhone } from "react-icons/hi2";

// Define the Product type
interface Product {
    id: string;
    name: string;
    price: number;
    quantity: number;
    image_small: string;
}

interface SummaryCartProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>;
    userData: any;
    formData: any;
    payMethod: string;
    deliveryMethod: string;
    cartItems: Product[];
}

// App component to render the application
export default function SummaryCart({ setCurrentStep, formData, userData, deliveryMethod, payMethod, cartItems }: Readonly<SummaryCartProps>) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const totalItemPrice = (item: Product) => {
        return (item.price * item.quantity).toFixed(2);
    }

    const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

    const itemTemplate = (item: Product) => {
        const getQuantityText = (quantity: number) => {
            if (quantity === 1) {
                return `${quantity} kus`;
            } else if (quantity > 1 && quantity < 5) {
                return `${quantity} kusy`;
            } else {
                return `${quantity} kusů`;
            }
        };

        return (
            <div className="summary cart-item">
                <div className="cart-item-title">
                    <img src={item.image_small} alt={item.name} />
                </div>

                <div className="cart-item-info">
                    <h3>{item.name}</h3>
                    <div>
                        <p>{getQuantityText(item.quantity)}</p>
                        <p className={"cart-item-price"}>{totalItemPrice(item)} Kč</p>
                    </div>
                </div>
            </div>
        );
    };

    const submitOrder = () => {
        // Sending the order to the backend
        setIsSubmitting(true);
        async function sendOrder() {
            try {
                const appUrl = process.env.REACT_APP_URL;
                const response = await fetch(`${appUrl}/api/orders`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        first_name: formData.firstName,
                        last_name: formData.lastName,
                        phone_number: formData.phoneNumber,
                        email: formData.email,
                        order_items: cartItems.map(item => ({
                            product_id: item.id,
                            quantity: item.quantity,
                        })),
                        payment_type: payMethod,
                        street: formData.street,
                        city: formData.city,
                        postal_code: formData.postalCode,
                        delivery_type: deliveryMethod,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to submit the order");
                }
                localStorage.removeItem("cart");
                const data = await response.json();

                if (data.redirect_url) {
                    window.location.href = data.redirect_url;
                } else {
                    console.log("Order submitted successfully");
                    setCurrentStep(Step.Finish);
                }
            } catch (error) {
                console.error("Error submitting order:", error);
            } finally {
                setIsSubmitting(false);
            }
        }

        sendOrder();
    }

    return (
        <div className={"cart-container cart-size"}>
            <div className={"p-card cart cart-title"}>
                <h1>Souhrn objednávky</h1>
            </div>

            <div className={"row-cart-container middle-cart dataview-scroll"}>
                <div className={"middle-cart row-cart p-card cart cart-content contact-summary"}>
                    <div className={"summary-content"}>
                        <h2>Kontaktní údaje</h2>
                        <span className={"name"}>{formData.firstName} {formData.lastName}</span>

                        <span> <HiOutlineAtSymbol /> {formData.email}</span>
                        <span> <HiPhone /> {formData.phoneNumber}</span>
                    </div>

                    <div className={"summary-content"}>
                        <h2>Místo doručení</h2>
                        <div className={"summary-address"}>
                            <HiOutlineMap />
                            <div>
                                {deliveryMethod === "personal" &&  <span>K vyzvednutí</span>}
                                <span>{formData.city} {formData.postalCode}</span>
                                <span> {formData.street}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"middle-cart row-cart p-card cart dataview-scroll"}>
                    <h2>Košík</h2>
                    <div className={"dataview-scroll"}>
                        <DataView value={cartItems}
                                  layout={"list"}
                                  itemTemplate={itemTemplate}
                                  emptyMessage={"Košík je prázdný."}
                                  lazy={true}
                        />
                    </div>
                    <div className={"total-price"}>
                        <h3>Celková cena</h3>
                        <div className={"price-chip"}>
                            <span>{totalPrice} Kč</span>
                            {
                                payMethod === "card" ? (
                                    <span className={"p-chip"}> <HiOutlineCreditCard /> Kartou </span>
                                ) : (
                                    <span className={"p-chip"}> <HiOutlineCash /> Hotově </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={"cart-footer p-card cart end-cart"}>
                <button
                    className="button-text p-button"
                    onClick={() => setCurrentStep(Step.Payment)}
                    disabled={isSubmitting}
                >
                    Zpět
                </button>

                {payMethod === "card" ? (
                    <button
                        onClick={submitOrder}
                        className="button p-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Odesílání..." : "Zaplatit"}
                    </button>
                ) : (
                    <button
                        onClick={submitOrder}
                        className="button p-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Odesílání..." : "Dokončit"}
                    </button>
                )}
            </div>
        </div>
    );
}
