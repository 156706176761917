import React, { useRef } from "react";
import { Step } from "./cartFlow";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { FloatLabel } from "primereact/floatlabel";
import { formatPhone, validPhone } from "../../validators/phoneValidator";
import { validEmail } from "../../validators/emailValidator";
import { Toast } from "primereact/toast";
import { HiOutlineCash, HiOutlineCreditCard, HiOutlineTruck } from "react-icons/hi";
import { HiMapPin } from "react-icons/hi2";

interface PayMethodProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>;
    userData: any;
    loading: boolean;
    error: any;
    formData: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    payMethod: string;
    setPayMethod: React.Dispatch<React.SetStateAction<string>>;
    deliveryMethod: string;
    setDeliveryMethod: React.Dispatch<React.SetStateAction<string>>;
    idCookie: boolean;
}

export function PayMethod({
                              setCurrentStep,
                              userData,
                              loading,
                              error,
                              formData,
                              setFormData,
                              payMethod,
                              setPayMethod,
                              deliveryMethod,
                              setDeliveryMethod,
                              idCookie
                          }: PayMethodProps) {

    const toast = useRef<Toast | null>(null);

    const confirmData = (phone: string, email: string) => {
        if (!isFilled) {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Všechny data musí být vyplněna" });
            return false;
        }
        if (!validEmail(email)) {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Neplatný e-mail" });
            return false;
        }
        if (!validPhone(phone)) {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Neplatné telefonní číslo" });
            return false;
        }

        phone = formatPhone(phone);
        setFormData({ ...formData, phoneNumber: phone });
        setCurrentStep(Step.Summary);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        console.error(error);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const isFilled = formData.firstName && formData.lastName && formData.email && formData.phoneNumber && (deliveryMethod !== "delivery" || (formData.city && formData.postalCode && formData.street));

    const isWholesaler = userData?.account_type === "premium"; // testing purposes so I don't have to switch accounts

    return (
        <div className={`cart-container cart-size ${deliveryMethod === "delivery" ? "auto-height" : ""}`}>
            <Toast ref={toast} position={"bottom-right"} />

            <div className={"cart-title cart p-card"}>
                <h1>Platba</h1>
            </div>

            <div className={"middle-cart p-card cart-content"}>
                <h2>Kontaktní údaje</h2>

                <form className={"user-form"} action="">
                    <div className={"name-container"}>
                        <FloatLabel>
                            <InputText
                                className={"text-input"}
                                id={"firstname"}
                                name="firstName"
                                onChange={handleInputChange}
                                value={formData.firstName}
                                keyfilter={/^[^<>*!]+$/}
                            />
                            <label htmlFor={"firstname"}>Jméno</label>
                        </FloatLabel>

                        <FloatLabel>
                            <InputText
                                className={"text-input"}
                                id={"lastname"}
                                name="lastName"
                                onChange={handleInputChange}
                                value={formData.lastName}
                            />
                            <label htmlFor={"lastname"}>Přijmení</label>
                        </FloatLabel>
                    </div>

                    <div className={"inputs-container"}>
                        <FloatLabel>
                            <InputText
                                className={"text-input"}
                                id={"email"}
                                name="email"
                                onChange={handleInputChange}
                                value={formData.email}
                            />
                            <label htmlFor={"email"}>E-mail</label>
                        </FloatLabel>

                        <FloatLabel>
                            <InputText
                                className={"text-input"}
                                id={"phone"}
                                name="phoneNumber"
                                onChange={e => setFormData({ ...formData, phoneNumber: e.target.value })}
                                value={formData.phoneNumber}
                            />
                            <label htmlFor={"phone"}>Telefonní číslo</label>
                        </FloatLabel>
                    </div>
                </form>
            </div>

            <div className={"p-card cart middle-cart cart-content"}>
                <div className={"radio-container"}>
                    <div className={"radio-group"}>
                        <h2>Způsob doručení</h2>

                        <div>
                            <div className={"radio"}>
                                <RadioButton
                                    inputId={"deliveryPersonal"}
                                    value={"personal"}
                                    onChange={(e) => {
                                        setDeliveryMethod(e.value);
                                        setFormData({
                                            ...formData,
                                            city: "Moravská Ostrava",
                                            street: "Matiční",
                                            postalCode: "2740-20"
                                        });
                                    }}
                                    checked={deliveryMethod === "personal"}
                                />
                                <label id={"deliveryPersonalLabel"} htmlFor={"deliveryPersonal"}> <HiMapPin /> Vyzvednout osobně</label>

                                {
                                    !isWholesaler && (
                                        <Tooltip
                                            target={"#deliveryPersonalLabel"}
                                            position={"right"}
                                            content={"Možnost dodání jen pro velkoobchodníky"}
                                        />
                                    )
                                }
                            </div>

                            {isWholesaler && (
                                <div className={"radio"}>
                                    <RadioButton
                                        inputId={"delivery"}
                                        value={"delivery"}
                                        onChange={(e) => {
                                            setDeliveryMethod(e.value);
                                            setFormData({
                                                ...formData,
                                                city: "",
                                                street: "",
                                                postalCode: ""
                                            });
                                        }}
                                        checked={deliveryMethod === "delivery"}
                                    />
                                    <label htmlFor={"delivery"}> <HiOutlineTruck /> Doručit na danou adresu</label>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={"radio-group"}>
                        <h2>Vyberte platební metodu</h2>

                        <div>
                            <div className={"radio"}>
                                <RadioButton
                                    inputId={"payCash"}
                                    value={"cash"}
                                    onChange={(e) => setPayMethod(e.value)}
                                    checked={payMethod === "cash"}
                                />
                                <label htmlFor={"payCash"}> <HiOutlineCash /> Hotově</label>
                            </div>

                            <div className={"radio"}>
                                <RadioButton
                                    inputId={"payCard"}
                                    value={"card"}
                                    onChange={(e) => setPayMethod(e.value)}
                                    checked={payMethod === "card"}
                                />
                                <label htmlFor={"payCard"}> <HiOutlineCreditCard /> Kartou</label>
                            </div>
                        </div>
                    </div>
                </div>

                {deliveryMethod === "delivery" && (
                    <>
                        <h3 id={"addressTitle"}>Adresa doručení</h3>
                        <form className={"user-form"}>
                            <Tooltip
                                target={"#addressTitle"}
                                position={"right"}
                                content={"Doručování jen v ČR"}
                            />

                            <div className={"name-container"}>
                                <InputText
                                    className={"text-input city"}
                                    placeholder={"Město"}
                                    value={formData.city}
                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                />

                                <InputText
                                    className={"text-input psc"}
                                    placeholder={"PSČ"}
                                    value={formData.postalCode}
                                    keyfilter="int"
                                    onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                                />
                            </div>

                            <InputText
                                className={"text-input"}
                                placeholder={"Ulice"}
                                value={formData.street}
                                onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                            />
                        </form>
                    </>
                )}
            </div>

            <div className={"cart-footer end-cart cart p-card"}>
                <button className={"button-text p-button"} onClick={() => setCurrentStep(Step.Cart)}>Zpět</button>
                <button className={"button p-button"} onClick={() => confirmData(formData.phoneNumber, formData.email)}>Dokončit</button>
            </div>
        </div>
    );
}