import React, { useEffect, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useParams } from "react-router-dom";
import { HiPhone, HiCreditCard, HiOutlineAtSymbol, HiOutlineCash } from "react-icons/hi";
import { Dropdown } from "primereact/dropdown";
import "../css/orderOverview.css";
import useOrder from "../hooks/useOrder"; // Import the new hook
import useUserData from "../hooks/useUserData";
import useAuth from "../hooks/useAuth";
import { translatePayment, translateStatus, Translation } from "../utils/translation";
import { OrderItem } from "../utils/interfaces";
import { HiMapPin } from "react-icons/hi2";

const OrderOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { order, loading, error } = useOrder(id as string); // Use the new hook
    const [status, setStatus] = useState<string | null>(order?.status || null);
    const [updating, setUpdating] = useState<boolean>(false);

    const { userData } = useUserData(useAuth());
    const isAdmin = userData?.account_type === "admin";

    useEffect(() => {
        if (order) {
            setStatus(order.status);
        }
    }, [order]);

    const statusOptions = [
        { label: "Doručeno", value: "delivered" },
        { label: "Zrušeno", value: "cancelled" },
        { label: "Zaplaceno", value: "paid" },
        { label: "Nezaplaceno", value: "unpaid" },
        { label: "Čekající", value: "pending" }
    ];

    const handleStatusChange = async (e: { value: string }) => {
        const newStatus = e.value;
        setStatus(newStatus);
        setUpdating(true);

        const appUrl = process.env.REACT_APP_URL;

        try {
            const response = await fetch(`${appUrl}/api/orders/${id}`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Optionally update local state or refetch order data
        } catch (error: unknown) {
            console.error('Failed to update order status:', error);
        } finally {
            setUpdating(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const orderImage = (data: OrderItem) => {
        return <img src={data.product.image_small} alt={data.product.name} className={"order-image"} />;
    }

    const orderPrice = (data: OrderItem) => {
        return <span>{data.price} Kč</span>;
    }

    return (
        <div className={"content justify-center order"}>
            <div className={"p-card"}>
                <h1>Přehled objednávky - {order?.order_number}</h1>
                {order && (
                    <>
                        <div className={"row"}>
                            <div className={"contacts-info"}>
                                <h2>Kontaktní údaje</h2>
                                <h3> {order.first_name} {order.last_name}</h3>
                                <p><HiOutlineAtSymbol /> {order.email}</p>
                                <p><HiPhone /> {order.phone_number}</p>
                                <p><HiMapPin /> {order.street}, {order.city}, {order.postal_code}</p>
                            </div>

                            <div className={"status-info"}>
                                <h2>Stav objednávky</h2>
                                <p>{order.total} Kč</p>
                                <p className={"p-chip"}>{order.payment_type === "cash" ? <HiOutlineCash /> : <HiCreditCard />} {translatePayment(order.payment_type as keyof typeof translatePayment)}</p>

                                {isAdmin ? (
                                    <Dropdown
                                        value={status}
                                        options={statusOptions}
                                        onChange={handleStatusChange}
                                        placeholder="Vybrat stav"
                                        disabled={updating}
                                    />
                                ) : (
                                    <p>{translateStatus(order.status as keyof typeof Translation)}</p>
                                )}
                            </div>
                        </div>

                        <div>
                            <h2>Produkty</h2>
                            <DataTable value={order.order_items}>
                                <Column
                                    field="product.image_small"
                                    header="Image"
                                    headerStyle={{ width: '10%' }}
                                    body={orderImage}
                                />
                                <Column field="product.name" header="Název" />
                                <Column
                                    field="price"
                                    header="Cena"
                                    body={orderPrice}
                                />
                                <Column field="quantity" header="Počet" />
                            </DataTable>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default OrderOverview;