import React from 'react';
import ReactDOM from 'react-dom/client';
import "primereact/resources/themes/md-light-deeppurple/theme.css";
import './css/index.css';
import App from "./App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <App />
);
