import React, { useEffect, useState } from "react";
import Hero from "../utils/hero";
import { Card } from "primereact/card";
import "../css/products.css";
import { HiMinus, HiPlus } from "react-icons/hi";
import { DeferredContent } from "primereact/deferredcontent";
import { MultiSelect } from "primereact/multiselect";
import { Link } from "react-router-dom";
import { useCart } from "../hooks/useCart";
import { Product } from "../utils/interfaces";
import { useAllProducts } from "../hooks/useAllProducts";

export default function Products() {
    const { cart, handleProductAction } = useCart(); // Use the custom hook for cart management
    const { products: fetchedProducts, loading, error } = useAllProducts(); // Use the custom hook for fetching products
    const [productTypes, setProductTypes] = useState<string[]>([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        setProducts(fetchedProducts);
        const types = Array.from(new Set(fetchedProducts.flatMap(product => product.type_names)));
        setProductTypes(types);
    }, [fetchedProducts]);

    const footer = (product: Product) => {
        const cartItem = cart[product.id];
        const isMaxQuantity = cartItem && cartItem.quantity >= cartItem.maximum_quantity;

        return (
            <span className={"align-center"}>
            {cartItem?.quantity === 0 || cartItem?.quantity === undefined ? (
                <button
                    onClick={() => handleProductAction({ type: 'ADD', product })}
                    className={"button p-button"}
                >
                    Přidat do košíku
                </button>
            ) : (
                <>
                    <button
                        onClick={() => handleProductAction({ type: 'REMOVE', productId: product.id })}
                        className={"unset icon-button"}
                    >
                        <HiMinus />
                    </button>

                    <span className={"quantity-display"}>
                        {cartItem?.quantity ?? 0}
                    </span>

                    <button
                        onClick={() => handleProductAction({ type: 'ADD', product })}
                        className={"unset icon-button"}
                        disabled={isMaxQuantity}
                    >
                        <HiPlus />
                    </button>
                </>
            )}
        </span>
        );
    };

    const itemHeader = (product: Product) => (
        <Link to={`/produkty/${product.id}`} className="product-link">
            <div className="custom-header">
                <img src={product.image_small} alt={product.name} />
                <h2  className={"p-card-title"}>{product.name}</h2>
            </div>
        </Link>
    );


    return (
        <>
            <Hero
                title={"Chlebíčky & Bagety"}
                description={""}
            />

            <div className={"products"}>
                <div className={"filter p-card"}>
                    <MultiSelect
                        options={productTypes.map((type) => ({ label: type, value: type }))}
                        placeholder={"Vyberte typ produktu"}
                        onChange={(e) => setSelectedProductTypes(e.value)}
                        value={selectedProductTypes}
                        display={"chip"}
                        filter
                    />
                </div>

                <div className={"card-container"}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>Error: {error}</div>
                    ) : (
                        products
                            .filter(product => selectedProductTypes.length === 0 || product.type_names.some(type => selectedProductTypes.includes(type)))
                            .map((product, index) => (
                                <DeferredContent key={index} className={"product"}>
                                    <Card
                                        id={product.id}
                                        header={itemHeader(product)}
                                        subTitle={`${product.price} Kč`}
                                        footer={footer(product)}
                                    />
                                </DeferredContent>
                            ))
                    )}
                </div>
            </div>
        </>
    );
}
