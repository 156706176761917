import React, { useEffect, useState } from "react";
import { Step } from "./cartFlow";
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from "react-icons/hi";
import {Link, useLocation} from "react-router-dom";

interface FinishOrderProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>;
    payMethod: string;
}

export default function FinishOrder({ setCurrentStep, payMethod }: FinishOrderProps) {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const errorParam = queryParams.get("error");
        setHasError(errorParam != null);
    }, [location]);


    return (
        <div className="cart-container cart-size">
            <div className="p-card cart cart-title">
                <h1>{hasError ? "Chyba při zpracování objednávky" : "Objednávka dokončena"}</h1>
            </div>

            <div className="middle-cart p-card cart cart-content">
                <div className="finish-order-content">
                    {hasError ? (
                        <>
                            <HiOutlineExclamationCircle size={64} color="red" />
                            <h2>Omlouváme se, došlo k chybě</h2>
                            <p>Při zpracování vaší objednávky nastala neočekávaná chyba.</p>
                            <p>Pokud si myslíte, že by vše mělo být v pořádku, kontaktujte prosím administrátora.</p>
                            <p>Email: <a href="mailto:cafenero2022@gmail.com">cafenero2022@gmail.com</a></p>
                            <p>Telefon: <a href="tel:+420 733 171 883">+420 733 171 883</a></p>
                        </>
                    ) : (
                        <>
                            <HiOutlineCheckCircle size={64} color="green" />
                            <h2>Děkujeme za Vaši objednávku!</h2>
                            {payMethod === "cash" ? (
                                <p>Vaše objednávka byla úspěšně přijata a bude zpracována.</p>
                            ) : (
                                <p>Vaše platba byla úspěšně zpracována a objednávka bude brzy odeslána.</p>
                            )}
                            <p>Potvrzení objednávky bylo odesláno na Váš e-mail.</p>
                        </>
                    )}
                </div>
            </div>

            <div className="cart-footer p-card cart end-cart">
                <Link to={"/"} className="button p-button">
                    {hasError ? "Zpět na hlavní stránku" : "Pokračovat v nákupu"}
                </Link>
            </div>
        </div>
    );
}
