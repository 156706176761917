import React, { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";

export default function EditSchool() {
    // State for form fields
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [emailPrefix, setEmailPrefix] = useState<string>('');

    const navigate = useNavigate();
    const toast = useRef<Toast>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const url = `${process.env.REACT_APP_URL}/api/schools`;
        const method = 'POST'; // Use POST for creating

        const payload = {
            name,
            address: {
                street: address,
                city,
                postalCode,
            },
            email_prefix: emailPrefix,
        };

        try {
            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('School saved:', data);
            navigate('/admin'); // Redirect to admin page after saving
        } catch (error) {
            console.error('Failed to save school:', error);
            toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se uložit školu' });
        }
    };

    return (
        <div className="content justify-center admin">
            <Toast ref={toast} />
            <div className="p-card">
                <Link to="/admin">Zpět</Link>
                <h1>Nová škola</h1>
                <form onSubmit={handleSubmit}>
                    <FloatLabel>
                        <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        <label htmlFor="name">Název</label>
                    </FloatLabel>

                    <FloatLabel>
                        <InputText id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        <label htmlFor="address">Adresa</label>
                    </FloatLabel>

                    <FloatLabel>
                        <InputText id="city" value={city} onChange={(e) => setCity(e.target.value)} />
                        <label htmlFor="city">Město</label>
                    </FloatLabel>

                    <FloatLabel>
                        <InputText id="postalCode" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                        <label htmlFor="postalCode">PSČ</label>
                    </FloatLabel>

                    <FloatLabel>
                        <InputText id="emailPrefix" value={emailPrefix} onChange={(e) => setEmailPrefix(e.target.value)} />
                        <label htmlFor="emailPrefix">Emailový prefix</label>
                    </FloatLabel>

                    <button type="submit" className="button p-button">Vytvořit</button>
                </form>
            </div>
        </div>
    );
}
