export const validPhone = (phone: string): boolean => {
    // Remove all non-digit characters except the leading +
    phone = phone.replace(/(?!^\+)\D/g, "");

    // Check if the phone number is a valid Czech or Slovak phone number
    return phone.match(/^\+420\d{9}$|^\+421\d{9}$|^\d{9}$/) !== null;
};

export const formatPhone = (phone: string): string => {
    // Prepend +420 if the phone number does not start with +420 or +421
    if (!phone.startsWith("+420") && !phone.startsWith("+421")) {
        phone = "+420" + phone;
    }

    // Remove all non-digit characters except the leading +
    phone = phone.replace(/(?!^\+)\D/g, "");

    // Format the phone number with spaces between sets of three digits
    if (phone.length > 4) {
        phone = phone.replace(/(\+\d{1,3})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4").trim();
    }

    return phone;
};