import { useEffect, useState } from 'react';
import useAllUsers from './useAllUsers';
import {User} from "../utils/interfaces";

export const useAccountTypes = () => {
    const { users: fetchedAccounts } = useAllUsers();
    const [accountTypes, setAccountTypes] = useState<string[]>([]);

    useEffect(() => {
        if (fetchedAccounts.length > 0) {
            const types = fetchedAccounts.map((user: User) => user.account_type);
            const uniqueTypes = Array.from(new Set(types));
            setAccountTypes(uniqueTypes);
        }
    }, [fetchedAccounts]);

    return { accountTypes };
};