import { useEffect, useState } from "react";
import { Product } from "../utils/interfaces";

// Define the types of actions that can be performed on the cart
type ProductAction =
    | { type: 'ADD'; product: Product }
    | { type: 'REMOVE'; productId: string };

// Define the type for the cart item which includes the product and quantity
interface CartItem extends Product {
    quantity: number;
    minimum_quantity: number;
    maximum_quantity: number;
}

// Custom hook for managing the cart state and actions
export const useCart = () => {
    const [cart, setCart] = useState<{ [key: string]: CartItem }>({});

    // Load the cart from local storage when the component mounts
    useEffect(() => {
        const storedCart = localStorage.getItem("cart");
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
    }, []);

    // Save the cart to local storage whenever the cart state changes
    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    // Function to handle actions on the cart
    const handleProductAction = (action: ProductAction) => {
        setCart(prevCart => {
            const newCart = { ...prevCart };
            switch (action.type) {
                case 'ADD': {
                    const existingCartItem = newCart[action.product.id];
                    if (existingCartItem) {
                        const newQuantity = existingCartItem.quantity + 1;
                        if (newQuantity <= existingCartItem.maximum_quantity) {
                            existingCartItem.quantity = newQuantity;
                        }
                    } else {
                        newCart[action.product.id] = {
                            ...action.product,
                            quantity: action.product.minimum_quantity || 1
                        };
                    }
                    return newCart;
                }
                case 'REMOVE': {
                    const existingCartItem = newCart[action.productId];
                    if (existingCartItem) {
                        const newQuantity = existingCartItem.quantity - 1;
                        if (newQuantity < existingCartItem.minimum_quantity) {
                            existingCartItem.quantity = 0;
                        } else {
                            existingCartItem.quantity = newQuantity;
                        }
                        if (existingCartItem.quantity === 0) {
                            delete newCart[action.productId];
                        }
                    }
                    return newCart;
                }
                default:
                    return prevCart;
            }
        });
    };

    return { cart, handleProductAction };
};
