import React from 'react';
import {Link, useParams} from "react-router-dom";
import Form from './Form';
import { useWedding } from "../../hooks/useWedding"; // Import your wedding hook

export default function EditWedding() {
    const { id } = useParams<{ id: string }>();

    return (
        <div className="content justify-center admin">
            <div className="p-card">
                <Link to="/admin">Zpět</Link>
                <h1>{id? "Upravit svatbu" : "Nová svatba"}</h1>
                <Form useEventHook={useWedding} type="weddings" />
            </div>
        </div>
    );
}