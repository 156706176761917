import React, { useEffect, useState } from "react";
import { HiShoppingCart, HiUser} from "react-icons/hi2";
import { Link } from "react-router-dom";
import {HiMenu, HiX} from "react-icons/hi";
import Cookies from "js-cookie";

export default function Navbar() {
    const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setIsLoggedIn(!!Cookies.get("id"));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setDisplayWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        displayWidth > 768 ? (
            <div className="navbar">
                <div className="links navbar-content">
                    <Link to="/produkty">Chlebíčky & Bagety</Link>
                    <Link to="/svatby">Svatby & Catering</Link>
                    <Link to="/dezerty">Dezerty</Link>
                </div>
                <Link to="/">
                    <img src="/img/nero.svg" alt="logo" />
                </Link>
                <div className="icons navbar-content">
                    <Link to="/kosik">
                        <HiShoppingCart className="icon" />
                    </Link>
                    {isLoggedIn ? (
                        <Link to="/profil">
                            <HiUser className="icon" />
                        </Link>
                    ) : (
                        <Link to="/prihlasit">
                            <HiUser className="icon" />
                        </Link>
                    )}

                </div>
            </div>
        ) : (
            <div className="navbar">
                <Link to="/">
                    <img src="/img/nero.svg" alt="logo" />
                </Link>
                <button
                    className="unset drop-down-button"
                    onClick={handleMenu}
                >
                    {isMenuOpen ? <HiX /> : <HiMenu />}
                </button>
                <div
                    className="drop-down"
                    style={isMenuOpen ? { display: "flex" } : { display: "none" }}
                >
                    <Link to="/produkty">Chlebíčky & Bagety</Link>
                    <Link to="/svatby">Svatby & Catering</Link>
                    <Link to="/dezerty">Dezerty</Link>
                    <Link to="/kosik">
                        <HiShoppingCart className="icon" />
                    </Link>
                    <Link to="/prihlasit">
                        <HiUser className="icon" />
                    </Link>
                </div>
            </div>
        )
    );
}
