import {Link} from "react-router-dom";

export const NotFoundPage = () => {
    return (
        <div className={"content not-found"}>
            <div>
                <h1>404</h1>
                <h2>Stránka nenalezena</h2>
                <p>Zpět <Link to={"/"}>domů</Link> </p>
            </div>
        </div>
    )
}
