import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { FloatLabel } from "primereact/floatlabel";
import { Slider } from "primereact/slider";
import { FileUpload } from "primereact/fileupload";
import { MultiSelect } from "primereact/multiselect";
import { useProduct } from "../../hooks/useProduct";
import { useAllProducts } from "../../hooks/useAllProducts"; // Custom hook to fetch all products
import allergens from "../../utils/allergens";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import useProductTypes from "../../hooks/useProductType";

// Define type for allergens and categories
type Allergen = {
    key: number;
    label: string;
};

type Category = {
    id: string;
    name: string;
};

export default function EditProduct() {
    // State for form fields
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [quantity, setQuantity] = useState<[number, number]>([0, 100]);
    const [priceStandard, setPriceStandard] = useState<number | null>(null);
    const [priceWholeseller, setPriceWholeseller] = useState<number | null>(null);
    const [weight, setWeight] = useState<string | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const [selectedAllergens, setSelectedAllergens] = useState<string[]>([]);
    const [types, setTypes] = useState<string[]>([]); // State for types
    const { productTypes, loading: typesLoading, error: typesError } = useProductTypes();

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { product, loading, error } = useProduct(id as string);
    const { products } = useAllProducts(); // Fetch all products
    const toast = useRef<Toast>(null);

    useEffect(() => {
        if (id && product) {
            setName(product.name);
            setDescription(product.description ?? '');
            setQuantity([product.minimum_quantity, product.maximum_quantity]);
            setPriceStandard(product.price_standard);
            setPriceWholeseller(product.price_premium);
            setTypes(product.type_names);
            setWeight(product.weight);
            setSelectedAllergens(product.allergens || []);
        }
    }, [id, product]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const url = id ? `${process.env.REACT_APP_URL}/api/products/${id}` : `${process.env.REACT_APP_URL}/api/products`;
        const method = id ? 'PUT' : 'POST'; // Use PUT for updating, POST for creating

        if (method === 'POST') {
            if (!image) {
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Obrázek je povinný pro vytvoření nového produktu' });
                return; // Exit the function if no image is provided
            }

            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            types.forEach((type, index) => {
                formData.append(`types[${index}]`, type);
            });
            formData.append('price_standard', (priceStandard ?? 0).toString());
            formData.append('price_premium', (priceWholeseller ?? 0).toString());
            formData.append('minimum_quantity', quantity[0].toString());
            formData.append('maximum_quantity', quantity[1].toString());
            formData.append('weight', weight ? weight.toString() : ''); // Convert weight to string
            formData.append('image', image); // Append image for POST
            selectedAllergens.forEach((allergen, index) => {
                formData.append(`allergens[${index}]`, allergen);
            });

            try {
                const response = await fetch(url, {
                    method,
                    credentials: 'include',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Product saved:', data);
                navigate('/admin'); // Redirect to admin page after saving
            } catch (error) {
                console.error('Failed to save product:', error);
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se uložit produkt' });
            }

        } else {
            const payload = {
                name,
                description,
                types,
                price_standard: priceStandard ?? 0,
                price_premium: priceWholeseller ?? 0,
                minimum_quantity: quantity[0],
                maximum_quantity: quantity[1],
                weight: weight ? weight.toString() : '',
                allergens: selectedAllergens,
            };

            try {
                const response = await fetch(url, {
                    method,
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Product saved:', data);
                navigate('/admin'); // Redirect to admin page after saving
            } catch (error) {
                console.error('Failed to save product:', error);
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se uložit produkt' });
            }
        }
    };

    const deleteProduct = () => {
        if (!id) return;

        fetch(`${process.env.REACT_APP_URL}/api/products/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // Check if the response has content
                return response.text().then(text => text ? JSON.parse(text) : {});
            })
            .then(data => {
                navigate('/admin'); // Redirect to admin page after deleting
            })
            .catch(error => {
                console.error('Failed to delete product:', error);
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se smazat produkt' });
            });
    };

    const confirmDeleteProduct = () => {
        confirmDialog({
            message: "Opravdu chcete smazat tento produkt?",
            header: "Potvrdit",
            acceptLabel: "Ano",
            rejectLabel: "Ne",
            accept: deleteProduct,
        });
    };

    const allergenOptions: Allergen[] = Object.entries(allergens).map(([key, label]) => ({
        key: parseInt(key, 10),
        label: `${key}. ${label}`,
    }));

    // Ensure unique keys for allergenOptions
    const uniqueAllergenOptions = Array.from(new Set(allergenOptions.map(a => a.key)))
        .map(key => allergenOptions.find(a => a.key === key));

    // Extract categories from products and remove duplicates
    const categoryOptions: Category[] = Array.from(new Set(products.flatMap(product => product.type_names)))
        .map((name, index) => ({
            id: index.toString(),
            name,
        }));

    if (id && loading) return <div>Loading...</div>;
    if (id && error) return <div>Error: {error}</div>;

    console.log(product?.allergens);

    return (
        <div className="content justify-center admin">
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="p-card">
                <Link to="/admin">Zpět</Link>
                <h1>{id ? 'Upravit produkt' : 'Nový produkt'}</h1>
                <form onSubmit={handleSubmit}>
                    <FloatLabel>
                        <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        <label htmlFor="name">Název</label>
                    </FloatLabel>

                    <FloatLabel>
                        <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <label htmlFor="description">Popis</label>
                    </FloatLabel>

                    <label htmlFor="quantity">Počet kusů</label>
                    <div className="slider-container">
                        <Slider
                            range
                            min={0}
                            max={100}
                            step={1}
                            value={quantity}
                            onChange={(e) => setQuantity(e.value as [number, number])}
                        />
                        <div className="slider-values">
                            <span>Min: {quantity[0]}</span>
                            <span>Max: {quantity[1]}</span>
                        </div>
                    </div>

                    <FloatLabel>
                        <InputText id="weight" value={weight ?? ''} onChange={(e) => setWeight(e.target.value)} />
                        <label htmlFor="weight">Hmotnost</label>
                    </FloatLabel>

                    <div className={"row"}>
                        <FloatLabel>
                            <InputNumber id="price-standard" value={priceStandard} onValueChange={(e) => setPriceStandard(e.value ?? null)} />
                            <label htmlFor="price-standard">Cena standard</label>
                        </FloatLabel>

                        <FloatLabel>
                            <InputNumber id="price-wholeseller" value={priceWholeseller} onValueChange={(e) => setPriceWholeseller(e.value ?? null)} />
                            <label htmlFor="price-wholeseller">Cena velkoodběratel</label>
                        </FloatLabel>
                    </div>

                    <div className={"row"}>
                        <FloatLabel>
                            <MultiSelect
                                value={types}
                                options={productTypes.map(type => ({ label: type.name, value: type.name }))}
                                onChange={(e) => setTypes(e.value)}
                                optionLabel="label"
                                optionValue="value"
                                display="chip"
                            />
                            <label htmlFor="category">Kategorie</label>
                        </FloatLabel>

                        <FloatLabel>
                            <MultiSelect
                                value={selectedAllergens}
                                options={uniqueAllergenOptions}
                                onChange={(e) => setSelectedAllergens(e.value)}
                                optionLabel="label"
                                optionValue="key"
                                display="chip"
                            />
                            <label htmlFor="allergens">Alergeny</label>
                        </FloatLabel>
                    </div>

                    {!id && (
                        <FileUpload
                            className="upload-image"
                            mode="basic"
                            chooseLabel="Vybrat obrázek"
                            name="demo[]"
                            accept="image/*"
                            maxFileSize={1000000}
                            customUpload
                            onSelect={(e) => {
                                if (e.files && e.files.length > 0) {
                                    setImage(e.files[0]); // Set the image state to the selected file
                                }
                            }}
                        />
                    )}

                    <button type="submit" className="button p-button">{id ? 'Upravit' : 'Vytvořit'}</button>
                    {id && <button type="button" className="button p-button" onClick={(e) => confirmDeleteProduct()}>Smazat</button>}
                </form>
            </div>
        </div>
    );
}