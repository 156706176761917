import React, { useEffect, useState } from "react";
import Hero from "../utils/hero";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { DeferredContent } from "primereact/deferredcontent";

interface EventCardProps {
    id: string;
    name: string;
    price: number;
    images: string[];
    type: string;
    description?: React.ReactNode;
}

export default function Weddings() {
    const [weddings, setWeddings] = useState<EventCardProps[]>([]);

    const appUrl = process.env.REACT_APP_URL;
    const imageUrl = process.env.REACT_APP_URL_IMAGE;
    useEffect(() => {
        fetch(`${appUrl}/api/events`)
            .then(response => response.json())
            .then(data => {
                // Modify the images array to include only the first image with REACT_APP_URL_IMAGE
                const modifiedData = data.map((wedding: EventCardProps) => ({
                    ...wedding,
                    images: wedding.images.length > 0 ? [`${imageUrl}/${wedding.images[0]}`] : []
                }));
                setWeddings(modifiedData);
            });
    }, []);

    const footer = (id: string) => (
        <Link className={"button p-button"} to={`/svatby/${id}`}>
            Zjistit více
        </Link>
    );

    return (
        <>
            <Hero
                title="Svatby"
                description=""
            />

            <div className="justify-center">
                <div className="card-container">
                    {weddings.map((wedding, index) => (
                        <DeferredContent key={index}>
                            <Card
                                id={wedding.id}
                                title={wedding.name}
                                subTitle={`${wedding.price} Kč`}
                                header={
                                    <img
                                        src={wedding.images[0]}
                                        alt={wedding.name}
                                        style={{width: '100%', height: '200px', objectFit: 'cover'}}
                                    />
                                }
                                footer={footer(wedding.id)}
                            >
                            </Card>
                        </DeferredContent>
                    ))}
                </div>
            </div>
        </>
    );
}

export function Caterings() {
    const [caterings, setCaterings] = useState<EventCardProps[]>([]);

    const appUrl = process.env.REACT_APP_URL;
    const imageUrl = process.env.REACT_APP_URL_IMAGE;
    useEffect(() => {
        fetch(`${appUrl}/api/caterings`)
            .then(response => response.json())
            .then(data => {
                // Modify the images array to include only the first image with REACT_APP_URL_IMAGE
                const modifiedData = data.map((catering: EventCardProps) => ({
                    ...catering,
                    images: catering.images.length > 0 ? [`${imageUrl}/${catering.images[0]}`] : []
                }));
                setCaterings(modifiedData);
            });
    }, []);

    const footer = (id: string) => (
        <Link className={"button p-button"} to={`/catering/${id}`}>
            Zjistit více
        </Link>
    );

    return (
        <>
        <Hero
                title="Catering"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis cumque eum magnam non quibusdam? Aliquid architecto autem eligendi eum fuga hic impedit ipsum iste iure labore, mollitia omnis perferendis perspiciatis repudiandae sunt temporibus ut veritatis, voluptates! Ad, alias, at cumque ducimus eaque incidunt modi quos repellendus temporibus vel voluptates voluptatum!"
            />
            <div className="justify-center">
                <div className="card-container">
                    {caterings.map((catering, index) => (
                        <DeferredContent key={index}>
                            <Card
                                id={catering.id}
                                title={catering.name}
                                subTitle={`${catering.price} Kč`}
                                header={
                                    <img
                                        src={catering.images[0]}
                                        alt={catering.name}
                                        style={{width: '100%', height: '200px', objectFit: 'cover'}}
                                    />
                                }
                                footer={footer(catering.id)}
                            >
                            </Card>
                        </DeferredContent>
                    ))}
                </div>
            </div>
        </>
    );
}
