import React, { useRef, useState, useEffect } from "react";
import "../css/profile.css";
import { HiAdjustments, HiCheck, HiLockClosed, HiOutlineAtSymbol, HiOutlineInformationCircle, HiPhone } from "react-icons/hi";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { formatPhone, validPhone } from "../validators/phoneValidator";
import { validEmail } from "../validators/emailValidator";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import useUserData from "../hooks/useUserData";
import useAuth from "../hooks/useAuth";
import useOrders from "../hooks/useOrders";
import { OrderTable } from "../utils/OrderTable";

interface UserData {
    id: string | null;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone_number: string | null;
    account_type: string | null;
}

export default function UserPage() {
    const isAuthenticated = useAuth();
    const { userData, loading, error } = useUserData(isAuthenticated);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={"content profile"}>
            <OrderHistory userData={userData} />
            <UserInfo userData={userData} />
        </div>
    );
}

function UserInfo({ userData }: { userData: UserData }) {
    const [email, setEmail] = useState(userData.email);
    const [phone, setPhone] = useState(userData.phone_number);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const toast = useRef<Toast | null>(null);

    const showSettings = (id: string) => {
        const settings = document.getElementById(id);
        if (settings) {
            if (settings.style.display === "flex") {
                settings.style.display = "none";
            } else {
                settings.style.display = "flex";
            }
        }
    };

    const confirm = (data: string, dataType: string) => {
        confirmDialog({
            message: "Opravdu chcete provést změnu?",
            header: "Potvrdit",
            acceptLabel: "Ano",
            rejectLabel: "Ne",
            accept: () => {
                // API call to update the user data
                const appUrl = process.env.REACT_APP_URL;
                let requestBody = {};

                if (dataType === "email") {
                    requestBody = { email: data };
                } else if (dataType === "phone_number") {
                    requestBody = { phone_number: data };
                } else if (dataType === "password") {
                    requestBody = { password: data };
                }

                fetch(`${appUrl}/api/users/${userData.id}`, {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                })
                    .then(response => response.json())
                    .then(result => {
                        fetch(`${appUrl}/api/me`, {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        })
                            .then(response => response.json())
                            .then(data => {
                                setEmail(data.email);
                                setPhone(data.phone_number);
                            })
                            .catch(error => {
                                console.error("Error fetching user data:", error);
                            });
                    })
                    .catch(error => {
                        console.error("Error updating data:", error);
                    });
            },
            reject: () => {
                console.log("change canceled");
            }
        });
    };

    const validateEmailAndConfirm = () => {
        if (email && validEmail(email)) {
            confirm(email, "email");
        } else {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Neplatný email" });
        }
    };
    const validatePhoneAndConfirm = () => {
        if (phone && validPhone(phone)) {
            confirm(formatPhone(phone), "phone_number");
        } else {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Neplatné telefonní číslo" });
        }
    };

    const validatePasswordAndConfirm = () => {
        if (password && passwordConfirm && password === passwordConfirm && password.length >= 8) {
            confirm(password, "password");
        } else {
            toast.current?.show({ severity: "error", summary: "Chyba", detail: "Hesla se neshodují" });
        }
    };

    const logOut = async () => {
        const appUrl = process.env.REACT_APP_URL;

        const response = await fetch(`${appUrl}/api/logout`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (response.ok) {
            <Link to={"/"} />;
        } else {
            console.error("Failed to log out");
        }
    };

    return (
        <div className={"card p-card info"}>
            <ConfirmDialog />
            <Toast ref={toast} position={"bottom-right"} />
            <div className={"user-type"}>
                <div>
                    <h1>{userData.first_name} {userData.last_name}</h1>
                    <p>{userData.account_type} <HiOutlineInformationCircle /></p>

                    <Tooltip target=".user-type p" position="bottom"
                             content="User type can be either a regular user or an admin. Admins have access to all features." /> {/*Change text when we get more info about types*/}
                </div>
            </div>

            <div className={"contacts"}>
                <h2>Kontaktní údaje</h2>
                <p><HiOutlineAtSymbol /> {userData.email} </p>
                <p><HiPhone />  {userData.phone_number} </p>
            </div>

            <div className={"footer"}>
                <button onClick={() => showSettings("settings")} className={"unset"}><HiAdjustments /> Nastavení</button>
                <Link onClick={logOut} className={"button p-button"} to="/">Odhlásit se</Link>
            </div>

            <div id={"settings"} className={"settings"}>
                <button onClick={() => showSettings("email")} className={"unset"}>
                    <HiOutlineAtSymbol /> Upravit email
                </button>

                <form id={"email"} className="p-inputgroup">
                    <InputText onChange={e => setEmail(e.target.value)} placeholder={userData.email ?? ''} />

                    <button
                        type={"button"}
                        onClick={() => validateEmailAndConfirm()}
                        className="button p-button"
                    >
                        <HiCheck />
                    </button>
                </form>

                <button onClick={() => showSettings("phone_number")} className={"unset"}>
                    <HiPhone /> Upravit telefonní číslo
                </button>

                <form id={"phone_number"} className="p-inputgroup">
                    <InputText
                        onChange={e => setPhone(e.target.value)}
                        placeholder={userData.phone_number ?? ''}
                    />

                    <button
                        type={"button"}
                        onClick={() => validatePhoneAndConfirm()}
                        className="button p-button"
                    >
                        <HiCheck />
                    </button>
                </form>

                <button onClick={() => showSettings("password")} id={"password-btn"} className={"unset"}>
                    <HiLockClosed /> Změnit heslo
                </button>

                <form id={"password"} className={"passwords"}>
                    <Password
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Nové heslo"
                        toggleMask
                        feedback={false}
                    />

                    <div className="p-inputgroup">
                        <Password
                            onChange={e => setPasswordConfirm(e.target.value)}
                            placeholder="Potvrdit heslo"
                            toggleMask
                            feedback={false}
                        />

                        <button
                            type={"button"}
                            onClick={() => validatePasswordAndConfirm()}
                            className="button p-button"
                        >
                            <HiCheck />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function OrderHistory({ userData }: { userData: UserData }) {
    const { orders, loading, error } = useOrders();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={"card p-card history"}>
            <h1>Přehled objednávek</h1>

            <OrderTable orders={orders} />
        </div>
    );
}