import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Event } from "../../utils/interfaces";

interface FormProps {
    useEventHook: (id: string) => { event: Event | null, loading: boolean, error: string | null };
    type: string;
}

const Form: React.FC<FormProps> = ({ useEventHook, type }) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [price, setPrice] = useState<number | null>(null);
    const [images, setImages] = useState<File[]>([]);
    const [existingImages, setExistingImages] = useState<string[]>([]);

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { event, loading, error } = useEventHook(id as string);

    useEffect(() => {
        if (id && event) {
            setName(event.name || '');
            setDescription(event.description || '');
            setPrice(event.price ?? null);
            setExistingImages(event.images || []);
        }
    }, [id, event]);

    const handleImageSelect = (e: { files: File[] }) => {
        if (e.files && e.files.length > 0) {
            setImages(prevImages => [...prevImages, ...e.files]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const url = id ? `${process.env.REACT_APP_URL}/api/events/${id}` : `${process.env.REACT_APP_URL}/api/events`;
        const method = id ? 'PUT' : 'POST';

        let body: FormData | string;
        let headers: HeadersInit = {};

        if (id) {
            // For updates, send JSON
            body = JSON.stringify({
                name,
                description,
                price: price ?? 0,
            });
            headers['Content-Type'] = 'application/json';
        } else {
            // For new events, use FormData to include images
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', (price ?? 0).toString());
            images.forEach((image) => {
                formData.append('images[]', image);
            });
            body = formData;
        }

        try {
            const response = await fetch(url, {
                method: method,
                credentials: 'include',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Event saved:', data);
            navigate('/admin');
        } catch (error) {
            console.error('Failed to save event:', error);
        }
    };

    const deleteEvent = () => {
        if (!id) return;

        fetch(`${process.env.REACT_APP_URL}/api/events/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text().then(text => text ? JSON.parse(text) : {});
            })
            .then(() => {
                navigate('/admin');
            })
            .catch(error => {
                console.error('Failed to delete event:', error);
            });
    }

    const confirmDeleteEvent = () => {
        confirmDialog({
            message: `Opravdu chcete smazat ${type === "catering" ? "tento catering" : "tuto svatbu"} ?`,
            header: "Potvrdit",
            acceptLabel: "Ano",
            rejectLabel: "Ne",
            accept: deleteEvent,
        });
    };

    if (id && loading) return <div>Loading...</div>;
    if (id && error) return <div>Error: {error}</div>;

    return (
        <form onSubmit={handleSubmit}>
            <ConfirmDialog />
            <div className="p-field">
                <label htmlFor="name">Název</label>
                <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="p-field">
                <label htmlFor="description">Popis</label>
                <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>

            <div className="p-field">
                <label htmlFor="price">Cena</label>
                <InputNumber id="price" value={price} onValueChange={(e) => setPrice(e.value ?? null)} />
            </div>

            {!id && (  // Only show FileUpload for new events
                <FileUpload
                    className="upload-image"
                    mode="advanced"
                    multiple
                    auto
                    chooseLabel="Vybrat obrázky"
                    name="images[]"
                    accept="image/*"
                    maxFileSize={1000000}
                    customUpload
                    uploadHandler={handleImageSelect}
                />
            )}

            {id && existingImages.length > 0 && (
                <div>
                    <h3>Existing Images</h3>
                    {existingImages.map((img, index) => (
                        <img key={index} src={img} alt={`Event image ${index + 1}`} style={{width: '100px', height: '100px', objectFit: 'cover', margin: '5px'}} />
                    ))}
                </div>
            )}

            <button type="submit" className="p-button">{id ? 'Upravit' : 'Vytvořit'}</button>
            {id && <button type="button" className="p-button" onClick={confirmDeleteEvent}>Smazat</button>}
        </form>
    );
};

export default Form;
