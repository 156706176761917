import {useEffect, useState} from "react";
import { Event } from "../utils/interfaces";

export const useAllWeddings = () => {
    const [weddings, setWeddings] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL;

        const fetchProducts = async () => {
            try {
                const response = await fetch(`${appUrl}/api/events`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setWeddings(data);
            } catch (error: unknown) {
                console.error('Failed to fetch products:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    return { weddings, loading, error };
}
