import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Order, OrderItem} from "./interfaces";
import {translateStatus, Translation} from "./translation";
import React from "react";
import {Link} from "react-router-dom";
import useUserData from "../hooks/useUserData";
import useAuth from "../hooks/useAuth";

export function OrderTable (props: { orders: Order[] }) {
    const { orders } = props;
    const {userData} = useUserData(useAuth());
    const isAdmin = userData.account_type === "admin";

    const formatDate = (date: string): string => {
        return new Date(date).toLocaleString();
    }


    const orderNumber = (data: Order) => {

        return <Link to={`/${isAdmin? "admin" : "profil"}/objednavka/${data.id}`}>{data.order_number}</Link>;
    }

    const orderPrice = (data: Order) => {
        return <span>{data.total} Kč</span>
    }

    return (
        <DataTable
            value={orders}
            emptyMessage={"Žádné objednávky"}
            paginator
            rows={15}
            className="custom-paginator"
        >
            <Column
                field={"created_at"}
                header={"Vytvořeno"}
                body={(data) => formatDate(data.created_at)}
                sortable={true}
            />

            <Column
                field={"order_number"}
                header={"Číslo objednávky"}
                body={orderNumber}
            />

            <Column
                field={"total"}
                header={"Celkem"}
                body={orderPrice}
            />
            <Column
                field={"status"}
                header={"Stav"}
                body={(data) => translateStatus(data.status as keyof typeof Translation)}
            />
        </DataTable>
    );
}