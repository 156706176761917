import { useEffect, useState } from "react";
import {ProductType} from "../utils/interfaces";

const useProductTypes = () => {
    const [productTypes, setProductTypes] = useState<ProductType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL;

        const fetchProductTypes = async () => {
            try {
                const response = await fetch(`${appUrl}/api/types`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: ProductType[] = await response.json();
                setProductTypes(data);
            } catch (error: unknown) {
                console.error('Failed to fetch product types:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProductTypes();
    }, []);

    return { productTypes, loading, error };
};

export default useProductTypes;