import React, { useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./utils/navbar";
import Home from "./components/home";
import Products from "./components/products";
import UserPage from "./components/profile";
import useAuth from "./hooks/useAuth";
import CartFlow from "./components/cart/cartFlow";
import AdminPage from "./components/Admin/adminPage";
import useUserData from "./hooks/useUserData";
import { NotFoundPage } from "./utils/notFoundPage";
import { Footer } from "./utils/footer";
import Login, { Register } from "./components/userForm";
import Weddings from "./components/events";
import EditProduct from "./components/Admin/editProduct";
import EditWedding from "./components/Admin/editWedding";
import ProductDetail from "./components/itemPage/productDetail";
import WeddingDetail from "./components/itemPage/eventDetail";
import OrderOverview from "./components/orderOverview";
import EditSchool from "./components/Admin/editSchool";
import Desserts from "./components/desserts";

export default function App() {
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useAuth();
    const userData = useUserData(isAuthenticated);
    const isAdmin = userData.userData?.account_type === "admin";

    useEffect(() => {
        if (isAuthenticated !== null && userData !== null) {
            setLoading(false);
        }
    }, [isAuthenticated, userData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/produkty" element={<Products />} />
                <Route path="/svatby" element={<Weddings />} />
                <Route path="/dezerty" element={<Desserts />} />
                <Route path="/produkty/:id" element={<ProductDetail />} />
                <Route path="/svatby/:id" element={<WeddingDetail />} />

                {isAuthenticated ? (
                    <>
                        <Route path="/profil" element={isAdmin ? <Navigate to="/admin" /> : <UserPage />} />
                        <Route path="/prihlasit" element={<Navigate to={isAdmin ? "/admin" : "/profil"} />} />
                        <Route path="/registrovat" element={<Navigate to={isAdmin ? "/admin" : "/profil"} />} />
                        <Route path="/profil/objednavka/:id" element={<OrderOverview />} />
                    </>
                ) : (
                    <>
                        <Route path="/profil" element={<Navigate to="/prihlasit" />} />
                        <Route path="/prihlasit" element={<Login />} />
                        <Route path="/registrovat" element={<Register />} />
                    </>
                )}

                <Route path="/kosik" element={<CartFlow />} />

                {isAdmin ? (
                    <>
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/admin/novy-produkt" element={<EditProduct />} />
                        <Route path="/admin/nova-svatba" element={<EditWedding />} />
                        <Route path="/admin/nova-skola" element={<EditSchool />} />

                        <Route path="/admin/upravit-produkt/:id" element={<EditProduct />} />
                        <Route path="/admin/upravit-svatbu/:id" element={<EditWedding />} />
                        <Route path="/admin/upravit-skolu" element={<EditSchool />} />

                        <Route path="/admin/objednavka/:id" element={<OrderOverview />} />
                    </>
                ) : (
                    <Route path="/admin/*" element={<Navigate to="/prihlasit" />} />
                )}

                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <Footer />
        </BrowserRouter>
    );
}
