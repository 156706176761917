import { useState, useEffect } from "react";

interface UserData {
    id: string | null;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone_number: string | null;
    account_type: string | null;
}

const fetchUserData = async (
    setUserData: React.Dispatch<React.SetStateAction<UserData>>,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const appUrl = process.env.REACT_APP_URL;

    try {
        const response = await fetch(`${appUrl}/api/me`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            const data = await response.json();
            setUserData(data);
        } else {
            setError("Failed to fetch user data");
        }
    } catch (error) {
        setError("Error fetching user data");
    } finally {
        setLoading(false);
    }
};

const useUserData = (isAuthenticated: boolean) => {
    const [userData, setUserData] = useState<UserData>({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        account_type: null,
    });

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserData(setUserData, setError, setLoading);
        } else {
            setLoading(false);
        }
    }, [isAuthenticated]);

    return { userData, loading, error };
};

export default useUserData;