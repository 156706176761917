import { useState, useEffect } from "react";
import { School } from "../utils/interfaces";

const useSchools = () => {
    const [schools, setSchools] = useState<School[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchSchools = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/schools`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: School[] = await response.json();
            setSchools(data);
        } catch (error: unknown) {
            console.error('Failed to fetch schools:', error);

            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchools();
    }, []);

    return { schools, loading, error, fetchSchools };
}

export default useSchools;