import React, { useEffect, useState } from "react";
import { DataView } from 'primereact/dataview';
import "../../css/cart.css";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Step } from "./cartFlow";

// Define the Product type
interface Product {
    id: string;
    name: string;
    price: number;
    quantity: number;
    image_small: string;
}


interface CartItemsProps {
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>;
    cartItems: Product[];
    setCartItems: React.Dispatch<React.SetStateAction<Product[]>>;
}

// CartItems component to manage the cart items
export default function CartItems({ setCurrentStep, cartItems, setCartItems }: CartItemsProps) {
    // types of actions, that can be performed on the cart
    type CartAction =
        | { type: 'ADD'; product: Product } // adds a product to the cart
        | { type: 'REMOVE'; productId: string } // removes a product from the cart
        | { type: 'INPUT_CHANGE'; id: string; value: string } // changes the quantity of a product
        | { type: 'BLUR'; id: string }; // removes the product from the cart if the quantity is 0

    /*
    * function to handle the actions on the cart
    * works with buttons and input fields in the cart
     */
    const handleCartAction = (action: CartAction) => {
        setCartItems(prevCartItems => {
            switch (action.type) {
                case 'ADD':
                    // adds 1 to the quantity
                    return prevCartItems.map(item =>
                        item.id === action.product.id ? { ...item, quantity: item.quantity + 1 } : item
                    );
                case 'REMOVE':
                    // subtracts 1 from the quantity, if the quantity is 0, removes the item from the cart
                    return prevCartItems
                        .map(item =>
                            item.id === action.productId ? { ...item, quantity: item.quantity - 1 } : item
                        )
                        .filter(item => item.quantity > 0);
                case 'INPUT_CHANGE':
                    // allows user to input a quantity
                    // if the input is not a number, set the quantity to 0
                    // if the input is a number, set the quantity to the input value and truncate the 0s at the beginning
                    return prevCartItems
                        .map(item =>
                            item.id === action.id ? { ...item, quantity: parseInt(action.value) || 0 } : item
                        );
                case 'BLUR':
                    // on leaving the input field, if the quantity is less than 0, set it to 0 and remove the item from the cart
                    return prevCartItems
                        .map(item =>
                            item.id === action.id ? { ...item, quantity: Math.max(item.quantity, 0) } : item
                        )
                        .filter(item => item.quantity > 0);
                default:
                    return prevCartItems;
            }
        });
    };

    const isEmpty = cartItems.length === 0;

    // counts the total price of the item and sets it to 2 decimal places
    const totalItemPrice = (item: Product) => {
        return (item.price * item.quantity).toFixed(2);
    }

    // counts the total price of the items in the cart and sets it to 2 decimal places
    const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

    /*
    * template for the items in the cart
    * displays the image, name, quantity, price per piece, and total price of the item
    * is used in the DataView component from PrimeReact
     */
    const itemTemplate = (item: Product) => {
        return (
            <div className="cart-item">
                <img src={item.image_small} alt={item.name}/>

                <div>
                    <h3 className={"cart-item-title"}>{item.name}</h3>

                    <div className="cart-item-info">
                        <span className={"item-count"}>
                            <button
                                onClick={() => handleCartAction({type: 'REMOVE', productId: item.id})}
                                className="unset iconButton"
                            >
                                <HiMinus/>
                            </button>

                            <input
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleCartAction({
                                    type: 'INPUT_CHANGE',
                                    id: item.id,
                                    value: e.target.value
                                })}
                                onBlur={() => handleCartAction({type: 'BLUR', id: item.id})}
                                className="unset num-input"
                            />

                            <button
                                onClick={() => handleCartAction({type: 'ADD', product: item})}
                                className="unset iconButton"
                            >
                                <HiPlus/>
                            </button>
                        </span>

                        <div className={"item-price"}>
                            <p>{item.price} Kč / kus</p>
                            <p>{totalItemPrice(item)} Kč</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={"cart-size cart-container"}>
            <div className={"p-card cart cart-title"}>
                <h1>Váš nákupní košík</h1>
            </div>

            <div className={"p-card cart middle-cart dataview-scroll"}>
                <DataView
                    value={cartItems}
                    layout={"list"}
                    itemTemplate={itemTemplate}
                    emptyMessage={"Košík je prázdný."}
                    lazy={true}
                />
            </div>

            <div className={"cart p-card end-cart"}>
                <div className={"total-price"}>
                    <h3>Celková cena:</h3>
                    <span> {totalPrice} Kč</span>
                </div>

                <div className={"cart-footer one-button"}>
                    { /* button is disabled if the cart is empty */}
                    <button disabled={isEmpty} className={"button p-button"}
                            onClick={() => setCurrentStep(Step.Payment)}>Další
                    </button>
                </div>
            </div>
        </div>

    );
}