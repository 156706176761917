import {useEffect, useState} from "react";
import { Product } from "../utils/interfaces";

export const useAllDesserts = () => {
const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const appUrl = process.env.REACT_APP_URL;

        const fetchProducts = async () => {
            try {
                const response = await fetch(`${appUrl}/api/products`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const filteredData = data.filter((product: Product) =>
                    !product.type_names || !product.type_names.some((tag: string) => tag.toLowerCase() !== 'dezerty')
                );
                filteredData.forEach((product: Product) => {
                    if (!product.image_small.startsWith("http")) {
                        product.image_small = `${appUrl}/storage/${product.image_small}`;
                    }
                });
                setProducts(filteredData);
            } catch (error: unknown) {
                console.error('Failed to fetch products:', error);

                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    return { products, loading, error };
}
