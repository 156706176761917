import React from "react";

interface HeroProps {
    title: string;
    description: string;
}

const Hero: React.FC<HeroProps> = ({ title, description }) => {
    return (
        <div className="hero content" style={{ backgroundImage: `url(/img/received_762684207419536.jpeg)` }}>
            <div>
                <h1 className={"title"}>{title}</h1>
                <p className={"description"}>{description}</p>
            </div>
        </div>
    );
};

export default Hero;
